
import { Options, Vue } from "vue-class-component";
import qs from "qs";
import moment from "moment";
import { Notify, Toast } from "vant";

export default class SendReprot extends Vue {
  private show = false;
  private reportUrl = "";
  private approvalForm = {
    id: "",
    applicationId: "",
    approver: "",
    approveTime: "",
    approvalOpinion: "",
    status: "",
    remark: "",
    checkOrgId: "",
  };
  private applyForm = {
    sampleList: [],
  };
  private showApproval(item: any) {
    this.applyForm = item;
    this.approvalForm.applicationId = item.id;
    this.approvalForm.approver = this.$store.state.userInfo.id;
    this.approvalForm.approveTime = moment().format("YYYY-MM-DD");
    this.approvalForm.approvalOpinion = "报告审核";
    this.approvalForm.checkOrgId = this.$store.state.userInfo.orgId;
    this.reportUrl =
      'http://server.ysddev.cn:1306/get-pdf-uri-stream?reportName=检测报告&input=[{"Name":"id","Values":["' +
      item.id +
      '"]},{"Name":"bzr","Values":[2]},{"Name":"qfr","Values":[2]}]+#toolbar=0';
    this.show = true;
  }
  //审核通过
  private examinePass() {
    this.approvalForm.status = "60";
    this.doApproval();
  }
  //审核退回
  private examinReturn() {
    this.approvalForm.status = "51";
    this.doApproval();
  }

  private doApproval() {
    const url = "/api/lims/applicationApproval/applicationReportSign";
    this.axios
      .post(url, this.approvalForm)
      .then((res: any) => {
        if (res.status.code === 1) {
          Toast("审核成功");
          this.$emit("doSearch");
          this.show = false;
          this.approvalForm = {
            id: "",
            applicationId: "",
            approver: "",
            approveTime: "",
            approvalOpinion: "",
            status: "",
            remark: "",
            checkOrgId: "",
          };
        } else {
          Notify(res.status.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private closePopup() {
    this.approvalForm = {
      id: "",
      applicationId: "",
      approver: "",
      approveTime: "",
      approvalOpinion: "",
      status: "",
      remark: "",
      checkOrgId: "",
    };
  }
  private onClickIcon() {
    console.log("开始调用flutter...");
    const _that = this;
    (window as any).flutter_inappwebview
      .callHandler("getReport", this.reportUrl)
      .then(function (result: any) {
        console.log(result);
      });
    console.log(this.reportUrl);
  }
}
