import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "sendReprot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_action_bar_icon = _resolveComponent("van-action-bar-icon")!
  const _component_van_action_bar_button = _resolveComponent("van-action-bar-button")!
  const _component_van_action_bar = _resolveComponent("van-action-bar")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
      position: "bottom",
      closeable: "",
      "close-icon-position": "top-right",
      teleport: "#app",
      onClose: _ctx.closePopup,
      style: { width: '100%', height: '95%' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_cell_group, {
          title: "签发",
          style: { width: '100%', height: '100%' }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell, {
              title: "样品编码",
              value: 
            _ctx.applyForm.sampleList.length > 0 ? _ctx.applyForm.sampleList[0].code : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "样品名称",
              value: 
            _ctx.applyForm.sampleList.length > 0 ? _ctx.applyForm.sampleList[0].name : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "样品分类",
              value: 
            _ctx.applyForm.sampleList.length > 0
              ? _ctx.applyForm.sampleList[0].sampleTypeName
              : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "检测目的",
              value: _ctx.applyForm.inspectGoal
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "采样日期",
              value: 
            _ctx.applyForm.sampleList.length > 0
              ? _ctx.applyForm.sampleList[0].samplingTime
              : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "采样人",
              value: 
            _ctx.applyForm.sampleList.length > 0
              ? _ctx.applyForm.sampleList[0].sampler
              : ''
          
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "编制日期",
              value: _ctx.applyForm.compileTime
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "编制人",
              value: _ctx.applyForm.compilerName
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "审核日期",
              value: _ctx.applyForm.approveTime
            }, null, 8, ["value"]),
            _createVNode(_component_van_cell, {
              title: "审核人",
              value: _ctx.applyForm.approverName
            }, null, 8, ["value"]),
            _createVNode(_component_van_field, {
              modelValue: _ctx.approvalForm.remark,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.approvalForm.remark) = $event)),
              rows: "2",
              autosize: "",
              label: "意见",
              type: "textarea",
              maxlength: "50",
              placeholder: "请输入意见",
              "show-word-limit": ""
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_van_action_bar, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_action_bar_icon, {
              icon: "chat-o",
              text: "查看报表",
              onClick: _ctx.onClickIcon
            }, null, 8, ["onClick"]),
            _createVNode(_component_van_action_bar_button, {
              type: "primary",
              text: "签发通过",
              onClick: _ctx.examinePass
            }, null, 8, ["onClick"]),
            _createVNode(_component_van_action_bar_button, {
              type: "warning",
              text: "驳回",
              onClick: _ctx.examinReturn
            }, null, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["show", "onClose"])
  ]))
}